import React from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import Banner2 from "../../../components/Banner2";


function About() {
  return (
    <>




      <Header title={"Qui sommes-nous"} />
      <Banner2 title={"Qui sommes-nous"} />




      <div className="agent-page content-area-2 pb-0">
        {/* Agent detail start */}
        <div className="agent-detail">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="sidebar mrb">
                  <div className="agent-3">
                    <div className="agent-photo">
                      <img
                        src="assets/img/about/qui-sommes-nous.png"
                        alt="avatar-5"
                        className="img-fluid"
                      />
                    </div>
                    <div className="agent-details">
                      <h5>
                        <a href="#">BK Arch’Immo</a>
                      </h5>
                      <div className="contact">
                        <p>
                          <a href="mailto:info@themevessel.com">
                            <i className="fa fa-home" />
                            2 Villa Saint Michel 75018 Paris
                          </a>
                        </p>
                        <p>
                          <a href="tel:+33 7 45 38 22 09">
                            {" "}
                            <i className="fa fa-envelope-o" />
                            audreycm@bk2v1mimmobilier.fr
                          </a>
                        </p>
                        <p>
                          <a href="#">
                            <i className="fa fa-phone" />
                            09 56 12 43 09
                          </a>
                        </p>
                        <p>
                          <a href="#">
                            <i className="fa fa-whatsapp" />
                            +337 55 95 28 06
                          </a>
                        </p>
                        <p>
                          <a href="#">
                            <i className="fa fa-id-card-o" />
                            CPI75012015000002025
                          </a>

                        </p>
                      </div>
                      <ul className="social-list clearfix">
                        <li>
                          <a href="#" className="facebook-bg">
                            <i className="fa fa-facebook" />
                          </a>
                        </li>
                        <li>
                          <a href="#" className="twitter-bg">
                            <i className="fa fa-twitter" />
                          </a>
                        </li>
                        <li>
                          <a href="#" className="instagram-bg">
                            <i className="fa fa-instagram" />
                          </a>
                        </li>
                        <li>
                          <a href="#" className="linkedin-bg">
                            <i className="fa fa-linkedin" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="contact-3 contact-agent widget-3">
                    <h5 className="sidebar-title">Contactez nous</h5>
                    <form action="#" method="GET" encType="multipart/form-data">
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Nom "
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          name="eamil"
                          className="form-control"
                          placeholder="Adresse e-mail"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          name="phone"
                          className="form-control"
                          placeholder="Téléphone "
                        />
                      </div>

                      <div className="form-group mb-0">
                        <button
                          type="submit"
                          className="btn btn-color btn-md btn-message btn-block"
                        >
                          Envoyer
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="agent-description">
                  <h3 className="heading-3">QUI SOMMES-NOUS ?</h3>
                  <p style={{ fontWeight: "bold", fontSize: "17px", color: "#af9483" }}>

                    Gestion complète de votre projet immobilier

                  </p>
                  <p className="">
                    Chez BK Arch’Immo, nous prenons en charge votre projet immobilier de A à Z grâce à une équipe d'experts dédiée.
                  </p>

                  <p className="">
                    Notre équipe comprend des courtiers, des chasseurs immobiliers et des architectes, tous prêts à vous accompagner à chaque étape de votre projet.

                  </p>

                  <p className="" style={{ fontWeight: "bold", color: "#af9483" }}>

                    Nos services comprennent :

                  </p>
                  <p className="">
                    <i style={{ fontWeight: "bold" }}>• Recherche du bien idéal : </i>Nos chasseurs immobiliers identifient et sélectionnent des propriétés qui répondent à vos critères spécifiques.
                  </p>
                  <p className="">
                    <i style={{ fontWeight: "bold" }}>• Financement et acquisition : </i> Nos courtiers accompagnent nos clients tout au long de leur recherche de financement.
                  </p>
                  <p className="">
                    Du montage du dossier de prêt , à la signature de l'acte de vente, en passant par la négociation des meilleurs taux, nous veillons à ce que chaque étape se déroule dans les meilleures conditions possibles
                  </p>
                  <p className="">
                    <i style={{ fontWeight: "bold" }}>• Conception et rénovation :  </i>Nos architectes conçoivent et supervisent les rénovations pour transformer votre bien selon vos besoins et vos goûts.
                  </p>
                  <p className="">
                    De la recherche du bien parfait jusqu'à la remise des clés, nous vous offrons une expérience sans souci et des résultats exceptionnels.
                  </p>
                  <p className="">
                    Avec BK Arch’Immo, vous bénéficiez d'un accompagnement personnalisé et d'une expertise remarquable pour réaliser vos projets immobiliers en toute sérénité.
                  </p>

                  <p style={{ fontWeight: "bold", fontSize: "17px", color: "#af9483" }}>
                    Nous vous aidons à construire en Afrique en toute sécurité.
                  </p>
                  <p className="">
                    Votre projet immobilier est sécurisé grâce à notre réseau de professionnels de confiance, à l'échelle internationale.
                  </p>
                  <p className="">
                    Nous vous accompagnons dans toutes les étapes , de l'acquisition du terrain à la construction de votre bien, en passant par la recherche de financement.
                  </p>
                  <p className="">
                    Vous bénéficierez de l'expérience de notaires, d'architectes, de constructeurs, d'aménageurs fonciers et d'experts juridiques, tous sélectionnés pour leur compétence et leur fiabilité.

                  </p>
                  <p className="">
                    De plus, vous avez la possibilité de suivre l'avancement de vos chantiers grâce à notre équipe de concierges sur place, assurant un suivi régulier et transparent des travaux.
                  </p>
                  <p className="" style={{ fontWeight: "bold", fontSize: "17px", color: "#af9483" }}> Services complémentaires.</p>
                  <p>
                    Nous offrons également des services de location courte durée, tels que Airbnb, pour maximiser la rentabilité de votre bien.
                  </p>
                  <p>
                    En outre, nous créons et organisons des voyages sur mesure, vous offrant une sérénité absolue et une expérience unique adaptée à vos besoins et envies.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Agent detail end */}
      </div >





      {/* Agent start */}
      < div className="agent content-area pt-5" >
        <div className="container">
          <div className="main-title">
            <h1>La Fondatrice</h1>

          </div>
          <div className="slick-slider-area">
            <div
              className="row slick-carousel wow fadeInUp delay-04s"
              data-slick='{"slidesToShow": 1, "responsive":[{"breakpoint": 1024,"settings":{"slidesToShow": 2}}, {"breakpoint": 768,"settings":{"slidesToShow": 1}}]}'
            >
              {/*  <div className="slick-slide-item wow">
                <div className="row agent-4 mb-0 text-center">
                  <div className="col-lg-5 col-md-12 col-pad pt-4" style={{ margin: "0 auto" }}>
                    <div className="photo" style={{ height: "200px", width: "200px", borderRadius: "50%", border: " 5px solid #b4c591" }}>
                      <img
                        src="assets/img/agent/agent1.png"
                        alt="avatar-4"
                        className="img-fluid zoom2"
                        style={{ height: "200px", width: "200px", borderRadius: "50%" }}
                      />

                    </div>

                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="details text-center">
                      <h3>
                        <a href="#">Vivianna Mbizimbote Keka </a>
                      </h3>

                      <div className="contact">
                        <p>
                          <a href="mailto:info@themevessel.com">
                            vmk@bk2vamimmobilier.fr
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row agent-4">

                  <div className="col-lg-12 col-md-12">
                    <hr />
                    <div className="agent-details text-center pb-4">

                      <p className="text-justify">Architecte, infographiste et chercheuse en science de l'habitat mélanoderme, a tracé un parcours professionnel marqué par la passion et la diversité. Ses débuts artistiques à l'École Supérieure d'Art de Rueil-Malmaison ont jeté les bases d'une carrière qui a évolué vers l'architecture, culminant avec l'obtention de son master à l'École Spéciale d'Architecture de Paris.</p>
                      <a href="#" className="btn btn-white-color mt-4 text-center" style={{ "text-transform": "none" }} data-toggle="modal"
                        data-target="#exampleModalViviane">
                        <i className="fa fa-arrow-right pr-2" />
                        Voir la biographie
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="slick-slide-item wow">
                <div className="row agent-4  mb-0">

                  <div className="col-lg-12 col-md-12">
                    <div className="details text-center">
                      <div className="col-lg-3 col-md-12 col-pad pt-4" style={{ margin: "0 auto" }}>
                        <div className="photo" style={{ margin: "0 auto", height: "200px", width: "200px", borderRadius: "50%", border: " 5px solid #b4c591", background: "#af9483" }}>
                          <img
                            src="assets/img/agent/agent2.png"
                            alt="avatar-4"
                            className="img-fluid zoom3"
                            style={{
                              height: "200px",
                              width: "131px",
                              position: "relative",
                              left: "12%",
                              marginTop: "10px",
                            }}
                          />

                        </div>
                      </div>
                      <h3>
                        <a href="#">Audrey Mballa Carpentier</a>
                      </h3>

                      <div className="contact">
                        <p>
                          <a href="mailto:info@themevessel.com">

                            audreycm@bk2v1mimmobilier.fr
                          </a>
                        </p>

                      </div>

                    </div>
                  </div>

                </div>
                <div className="row agent-4">

                  <div className="col-lg-12 col-md-12">
                    <hr />
                    <div className="agent-details text-center pb-4">

                      <p className="text-justify">Initialement implantée dans le domaine de l’évènementiel, Audrey est une conquérante du monde au dynamisme impressionnant, pour qui chaque challenge représente une occasion de se surpasser. Poussée par son sens de l’organisation, de l’écoute et de la recherche de solutions idoines à chaque problème, elle sait comprendre les attentes de l’autre.</p>
                      <a href="#" className="btn btn-white-color mt-4 text-center" style={{ "text-transform": "none" }} data-toggle="modal"
                        data-target="#exampleModalBioAudrey">
                        <i className="fa fa-arrow-right pr-2" />
                        Voir la biographie
                      </a>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div >
      {/* Agent end */}

      {/* Our newslatters 2 start */}
      <div className="our-newslatters-2">
        <div className="container">
          <div className="row inner">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="intro-text">
                <h3>S'abonner à la Newsletter</h3>
                <p>
                  Inscrivez-vous pour recevoir des informations et des mises à jour. Ne vous inquiétez pas, nous n'enverrons pas de spam !
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="Subscribe-box">
                <form className="form-inline" action="#" method="GET">
                  <input
                    type="text"
                    className="form-control mb-sm-0"
                    id="inlineFormInputName4"
                    placeholder="Email "
                  />
                  <button type="submit" className="btn">
                    Envoyer
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Our newslatters 2 end */}
      {/* Footer start */}
      <Footer />
      {/* Footer end */}
      {/* Full Page Search */}
      <div id="full-page-search">
        <button type="button" className="close">
          ×
        </button>
        <form action="#" className="search">
          <input type="search" defaultValue="" placeholder="type keyword(s) here" />
          <button type="button" className="btn btn-sm btn-color">
            Search
          </button>
        </form>
      </div>
      {/* Property Video Modal */}
      <div
        className="modal property-modal fade"
        id="propertyModal"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6 modal-left">
                  <div className="modal-left-content">
                    <div
                      id="modalCarousel"
                      className="carousel slide"
                      data-ride="carousel"
                    >
                      <div className="carousel-inner" role="listbox">
                        <div className="carousel-item active">
                          <iframe
                            className="modalIframe"
                            src="https://www.youtube.com/embed/5e0LxrLSzok"
                            allowFullScreen=""
                          />
                        </div>
                        <div className="carousel-item">
                          <img src="assets/img/test-alt.jpg" alt="Test ALT" />
                        </div>
                        <div className="carousel-item">
                          <img src="assets/img/test-alt-2.jpg" alt="Test ALT" />
                        </div>
                      </div>
                      <div className="btn-modal">
                        <a
                          className="control control-prev"
                          href="#modalCarousel"
                          role="button"
                          data-slide="prev"
                        >
                          <i className="fa fa-angle-left" />
                        </a>
                        <a
                          className="control control-next"
                          href="#modalCarousel"
                          role="button"
                          data-slide="next"
                        >
                          <i className="fa fa-angle-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 modal-right">
                  <div className="modal-right-content bg-white">
                    <h1>
                      <a href="#">Find Your Properties</a>
                    </h1>
                    <section>
                      <h3>Features</h3>
                      <ul className="bullets">
                        <li>
                          <i className="fa fa-arrow-right" /> Double Bed
                        </li>
                        <li>
                          <i className="flaticon-swimmer" /> Swimming Pool
                        </li>
                        <li>
                          <i className="flaticon-bath" /> 2 Bathroom
                        </li>
                        <li>
                          <i className="flaticon-car-repair" /> Garage
                        </li>
                        <li>
                          <i className="flaticon-parking" /> Parking
                        </li>
                        <li>
                          <i className="flaticon-theatre-masks" /> About Theater
                        </li>
                        <li>
                          <i className="flaticon-old-typical-phone" /> Telephone
                        </li>
                        <li>
                          <i className="flaticon-green-park-city-space" /> Private
                          space
                        </li>
                      </ul>
                    </section>
                    <section>
                      <h3>Overview</h3>
                      <dl>
                        <dt>Area</dt>
                        <dd>2500 Sq Ft</dd>
                        <dt>Condition</dt>
                        <dd>New</dd>
                        <dt>Year</dt>
                        <dd>2020</dd>
                        <dt>Price</dt>
                        <dd>
                          <strong>$178,000</strong>
                        </dd>
                      </dl>
                    </section>
                    <a
                      href="#"
                      className="btn btn-show btn-theme"
                    >
                      Show Detail
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Off-canvas sidebar */}
      <div className="off-canvas-sidebar">
        <div className="off-canvas-sidebar-wrapper">
          <div className="off-canvas-header">
            <a className="close-offcanvas" href="#">
              <span className="fa fa-times" />
            </a>
          </div>
          <div className="off-canvas-content">
            <aside className="canvas-widget">
              <div className="logo-sitebar text-center">
                <img src="assets/img/logos/logo.png" alt="logo" />
              </div>
            </aside>
            <aside className="canvas-widget">
              <ul className="menu">
                <li className="menu-item menu-item-has-children">
                  <a href="#">About</a>
                </li>
                <li className="menu-item">
                  <a href="#">Properties List</a>
                </li>
                <li className="menu-item">
                  <a href="#">Property Detail</a>
                </li>
                <li className="menu-item">
                  <a href="#">Blog</a>
                </li>
                <li className="menu-item">
                  <a href="#">About US</a>
                </li>
                <li className="menu-item">
                  <a href="#">Contact US</a>
                </li>
              </ul>
            </aside>
            <aside className="canvas-widget">
              <ul className="social-icons">
                <li>
                  <a href="#">
                    <i className="fa fa-facebook" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-google-plus" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-linkedin" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-vk" />
                  </a>
                </li>
              </ul>
            </aside>
          </div>
        </div>
      </div>





      <>

        <div
          className="modal fade"
          id="exampleModalBioAudrey"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-call modal-dialog-wide" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-center" id="exampleModalLabel">
                  Biographie d'Audrey Carpentier Mballa
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body text-justify">


                <p>L’écrivain François Cheng a dit : « Dans la vie, il est des rencontres stimulantes qui nous incitent à donner le meilleur de nous-même ». Cette citation décrit parfaitement Audrey Mballa, une jeune femme entrepreneure pour qui chaque rencontre avec un client est un moment mémorable et riche.</p>

                <p style={{ color: "#af9483" }}>Qui est Audrey Carpentier Mballa ? </p>
                <p>Qui est Audrey Carpentier Mballa ? Initialement implantée dans le domaine de l’évènementiel, Audrey est une conquérante du monde au dynamisme impressionnant, pour qui chaque challenge représente une occasion de se surpasser. Poussée par son sens de l’organisation, de l’écoute et de la recherche de solutions idoines à chaque problème, elle sait comprendre les attentes de l’autre.</p>

                <p>Le fait qu’elle soit attentive, bienveillante et à l’écoute des autres lui permet de comprendre facilement les motivations profondes de ses clients et deviner les aspirations non dévoilées de ces derniers. Par ailleurs, elle construit et dirige chaque projet comme si c’était le sien, comme si sa carrière en dépendait.</p>

                <p>Dans un secteur d’activité comme celui de l’immobilier, ces qualités font toute la différence ; et c’est exactement ce qu’Audrey propose, quelque chose de différent et d’original. Au sein de son agence, cette femme au haut potentiel prône la créativité et l’innovation comme étant les piliers de sa réussite.</p>

                <p style={{ color: "#af9483" }}>La carrière d’Audrey : un départ dans l’évènementiel pour terminer dans l’immobilier</p>

                <p>Éternelle insatiable, Audrey a toujours voulu dompter le secteur de l’immobilier. Et pourtant, elle a débuté sa carrière au Luxembourg dans le domaine de l’évènementiel. Après quelques années d’absence, un retour à la capitale française lui a enfin permis de réaliser ce rêve.</p>

                <p>Après avoir occupé les postes d’agent immobilier, de manager et enfin de directrice de l’agence immobilière pour laquelle elle travaillait, elle a finalement décidé de concrétiser son rêve en mettant sur pied sa propre agence immobilière. Cela est toujours apparu comme une évidence compte tenu du fait qu’Audrey a toujours su se démarquer de ses collaborateurs en figurant parmi les meilleurs agents et meilleurs vendeurs de la boite qui l’employait.</p>

                <p>Pour Audrey, concrétiser un projet immobilier ne se réduit pas à vendre des biens. Il s’agit avant toute chose d’accompagner le client vers la réalisation de son rêve. C’est grâce aux personnes aussi dynamiques, travailleuses, rigoureuses et loyales qu’elle, qu’Audrey réussit à satisfaire les attentes de chacun de ses clients, afin que chaque projet sur lequel elle travaille soit unique.</p>

                <p style={{ color: "#af9483" }}>L’entreprenariat au cœur de sa carrière professionnelle</p>

                <p>Entreprendre a toujours fait partie des aspirations d’Audrey depuis ses 20 ans. Dès l’âge de 22 ans, elle a joyeusement troqué les sorties en boite et les virées shopping contre de grands projets innovants et révolutionnaires, œuvrant pour la cause des femmes.</p>

                <p>Elle a commencé par créer une association ainsi qu’un club privé réservé aux femmes entrepreneures, qui partagent comme elle cette passion pour la création d’emplois, de talents et de richesses. Avec 2000 membres à ses côtés, inutile de préciser à quel point les talents de leader et de meneuse d’Audrey sont aiguisés.</p>

                <p>Elle a su accompagner ces femmes vers ce qui est devenu le plus grand projet de leur vie, comme elle le fera pour votre projet immobilier.</p>
              </div>

            </div>
          </div>
        </div>


        <div
          className="modal fade"
          id="exampleModalViviane"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-call modal-dialog-wide" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-center" id="exampleModalLabel">
                  Biographie de Vialy Viviana Mbizimbote Keka
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body text-justify">




                <p>Vialy Viviana Mbizimbote Keka, architecte, infographiste et chercheuse en science de l'habitat mélanoderme, a tracé un parcours professionnel marqué par la passion et la diversité. Ses débuts artistiques à l'École Supérieure d'Art de Rueil-Malmaison ont jeté les bases d'une carrière qui a évolué vers l'architecture, culminant avec l'obtention de son master à l'École Spéciale d'Architecture de Paris.</p>

                <p>En tant qu'architecte MOE, elle a dirigé la conception et la construction de projets variés, allant de villas à des logements collectifs. Sa fascination profonde pour l'histoire architecturale africaine et son engagement dans la recherche sur les matériaux biosourcés ont été honorés au salon Batiko International d'architecture, immobilier et design de Kinshasa. Elle a été finaliste en 2022 puis a finalement remporté le concours en 2023.</p>

                <p>Vialy Vivianna se distingue par sa vision écologique. Amoureuse de l’Afrique, en parallèle de ses projets en France, elle participe activement à de nombreux projets architecturaux en Afrique, notamment au Congo, son pays d’origine, qui regorge de ressources énergétiques. Elle cherche à développer des moyens novateurs pour assurer l'alimentation en eau et électricité de manière écologique, sans polluer l'environnement ni produire des déchets toxiques. Son objectif est d’accroître son impact et sa compétence en France, en se consacrant au service de la clientèle locale, tout en honorant son héritage africain.</p>

                <p>Son passé professionnel, comprenant des rôles d'architecte et d'architecte d'intérieur au sein d'entités variées, privées, publiques et d'État, a été une source d'enrichissement. Travaillant dans des bureaux d'études, des cabinets et des entreprises du bâtiment, elle a acquis une expérience diversifiée en étudiant différents projets et en collaborant avec divers corps de métier.</p>

                <p>Expert dans la conception assistée par ordinateur, Vialy Vivianna maîtrise les logiciels architecturaux les plus utilisés, fusionnant habilement son sens artistique avec des compétences techniques avancées en infographie 3D. Polyglotte, elle communique aisément en français, anglais, lingala, kikongo, allemand, kiswahili et tshiluba, élargissant ainsi son influence dans des contextes linguistiques variés.</p>

                <p>Vialy Mbizimbote Keka incarne une fusion exceptionnelle de créativité artistique, de curiosité intellectuelle, de compétences techniques avancées et de leadership solide. Sa présence dans le monde de l'architecture contemporaine constitue sans conteste une force motrice, contribuant de manière significative à l'évolution et à l'innovation dans son domaine.</p>
              </div>

            </div>
          </div>
        </div>

      </>
    </>
  );
}

export default About;

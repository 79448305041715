import React from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import Banner2 from "../../../components/Banner2";
import "./investissement.css";

function LocationResidentielle() {
    return (
        <>

            <Header title={"Location Residentielle"} />
            <Banner2 title={"Location Residentielle"} />





            <div className="about-us content-area-7 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-12">
                            <div className="about-info">
                                <div className="">
                                    <a className="btn btn-launch-one text-white">
                                        PROFESSIONNEL DE LA RECHERCHE LOCATIVE
                                    </a>
                                </div>

                                <h1 style={{ fontWeight: "bold", marginTop: "20px" }}>Votre location en moins de quartre semaines. </h1>
                                <h3> Arrêtez de chercher <br />
                                    On prend le relais pour vous trouver le bien idéal</h3>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#af9483" class="bi bi-check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                        <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                    </svg> Profitez d’annonces en off market
                                </div>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#af9483" class="bi bi-check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                        <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                    </svg> Optimisez votre temps
                                </div>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#af9483" class="bi bi-check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                        <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                    </svg> Un service personnalisé, toujours en adéquation avec le marché
                                </div>


                                <div className="" style={{ paddingBottom: 10, marginTop: "20px" }}>
                                    <a className="btn btn-launch btn-lg text-white" href="#" style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", width: "500px" }} target="_blank" rel="noopener">
                                        Confier ma recherche à un chasseurs kiswahili
                                    </a>
                                </div>

                                <div> <span style={{ fontWeight: "bold", marginRight: "5px" }}> 4.8 </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#af9483" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#af9483" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#af9483" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#af9483" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#af9483" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                    </svg>

                                </div>



                            </div>
                        </div>
                        <div className="carousel-inner col-lg-5 col-md-12" style={{ position: "relative", left: 20 }}>
                            <div className="carousel-item active" style={{ display: "flex", justifyContent: "center" }}>
                                <img
                                    src="assets/img/investissement/photo.jpeg"
                                    alt="property"
                                    className="img-fluid"
                                    style={{ height: "auto", width: "90%", marginTop: "20px", marginLeft: "100px" }}
                                />
                                <div style={{ position: "absolute", bottom: "22px", left: -10, width: "50%", backgroundColor: "#f2f2f2", padding: "10px", borderRadius: "10px" }}>
                                    <div style={{ width: "200px", fontSize: "11px" }}>
                                        <strong>Aïssatou - Paris</strong> <br /><br />
                                        &laquo;Audrey notre chasseuse m’a trouvée notre bien en 10 jours ❤️&raquo;
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div >



            <div class="blog-section content-area-2 bg-white design-perso">
                <div class="container" style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>

                    <div class="row" style={{ fontWeight: "bold" }} >
                        <div class="col-lg-3 col-md-6 col-sm-6 s-brd-2 wow fadeInLeft delay-04s">
                            <div class="">
                                <p style={{ fontSize: "25px" }}> <span style={{ marginRight: "5px" }}> + 83 <i style={{ color: "#af9483" }} className="fa fa-percent"></i></span>

                                </p>
                                <p style={{ fontSize: "13px" }}>de nos clients trouvent en moins de 4 semaines</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 wow fadeInUp delay-04s" style={{ visibility: "visible", animationName: "fadeInUp" }}>
                            <div class="">
                                <p style={{ fontSize: "25px", display: "flex", alignItems: "center", textAlign: "center", display: "flex", justifyContent: "center" }}>
                                    <span style={{ marginRight: "5px" }}>  12  <i style={{ color: "#af9483" }} className="fa fa-h-square"></i></span>
                                </p>

                                <p style={{ fontSize: "13px" }}>pour débuter votre chasse
                                    (Dès la réception de votre assurance loyer impayé.)</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 wow fadeInDown delay-04s" style={{ visibility: "visible", animationName: "fadeInDown" }}>
                            <div class="">
                                <p style={{ fontSize: "25px" }}>
                                    <span style={{ marginRight: "5px" }}> + 40 <i style={{ color: "#af9483" }} className="fa fa-percent"></i></span>

                                </p>

                                <p style={{ fontSize: "13px" }} >d’annonces en Off Market </p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 wow fadeInRight delay-04s" style={{ visibility: "visible", animationName: "fadeInRight" }}>
                            <div class="">
                                <p style={{ fontSize: "25px" }}>4.8 <i style={{ color: "#af9483" }} className="fa fa-star"></i></p>
                                <p style={{ fontSize: "13px" }}>note de nos clients</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {/* Our newslatters 2 start */}
            <div className="our-newslatters-2">
                <div className="container">
                    <div className="row inner">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="intro-text">
                                <h3>S'abonner à la Newsletter</h3>
                                <p>
                                    Inscrivez-vous pour recevoir des informations et des mises à jour. Ne vous inquiétez pas, nous n'enverrons pas de spam !
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="Subscribe-box">
                                <form className="form-inline" action="#" method="GET">
                                    <input
                                        type="text"
                                        className="form-control mb-sm-0"
                                        id="inlineFormInputName4"
                                        placeholder="Email "
                                    />
                                    <button type="submit" className="btn">
                                        Envoyer
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Our newslatters 2 end */}
            {/* Footer start */}
            <Footer />
            {/* Footer end */}
            {/* Full Page Search */}
            <div id="full-page-search">
                <button type="button" className="close">
                    ×
                </button>
                <form action="#" className="search">
                    <input type="search" defaultValue="" placeholder="type keyword(s) here" />
                    <button type="button" className="btn btn-sm btn-color">
                        Search
                    </button>
                </form>
            </div>
            {/* Property Video Modal */}
            <div
                className="modal property-modal fade"
                id="propertyModal"
                tabIndex={-1}
                role="dialog"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 modal-left">
                                    <div className="modal-left-content">
                                        <div
                                            id="modalCarousel"
                                            className="carousel slide"
                                            data-ride="carousel"
                                        >
                                            <div className="carousel-inner" role="listbox">
                                                <div className="carousel-item active">
                                                    <iframe
                                                        className="modalIframe"
                                                        src="https://www.youtube.com/embed/5e0LxrLSzok"
                                                        allowFullScreen=""
                                                    />
                                                </div>
                                                <div className="carousel-item">
                                                    <img src="assets/img/test-alt.jpg" alt="Test ALT" />
                                                </div>
                                                <div className="carousel-item">
                                                    <img src="assets/img/test-alt-2.jpg" alt="Test ALT" />
                                                </div>
                                            </div>
                                            <div className="btn-modal">
                                                <a
                                                    className="control control-prev"
                                                    href="#modalCarousel"
                                                    role="button"
                                                    data-slide="prev"
                                                >
                                                    <i className="fa fa-angle-left" />
                                                </a>
                                                <a
                                                    className="control control-next"
                                                    href="#modalCarousel"
                                                    role="button"
                                                    data-slide="next"
                                                >
                                                    <i className="fa fa-angle-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 modal-right">
                                    <div className="modal-right-content bg-white">
                                        <h1>
                                            <a href="#">Find Your Properties</a>
                                        </h1>
                                        <section>
                                            <h3>Features</h3>
                                            <ul className="bullets">
                                                <li>
                                                    <i className="fa fa-arrow-right" /> Double Bed
                                                </li>
                                                <li>
                                                    <i className="flaticon-swimmer" /> Swimming Pool
                                                </li>
                                                <li>
                                                    <i className="flaticon-bath" /> 2 Bathroom
                                                </li>
                                                <li>
                                                    <i className="flaticon-car-repair" /> Garage
                                                </li>
                                                <li>
                                                    <i className="flaticon-parking" /> Parking
                                                </li>
                                                <li>
                                                    <i className="flaticon-theatre-masks" /> About Theater
                                                </li>
                                                <li>
                                                    <i className="flaticon-old-typical-phone" /> Telephone
                                                </li>
                                                <li>
                                                    <i className="flaticon-green-park-city-space" /> Private
                                                    space
                                                </li>
                                            </ul>
                                        </section>
                                        <section>
                                            <h3>Overview</h3>
                                            <dl>
                                                <dt>Area</dt>
                                                <dd>2500 Sq Ft</dd>
                                                <dt>Condition</dt>
                                                <dd>New</dd>
                                                <dt>Year</dt>
                                                <dd>2020</dd>
                                                <dt>Price</dt>
                                                <dd>
                                                    <strong>$178,000</strong>
                                                </dd>
                                            </dl>
                                        </section>
                                        <a
                                            href="#"
                                            className="btn btn-show btn-theme"
                                        >
                                            Show Detail
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Off-canvas sidebar */}
            <div className="off-canvas-sidebar">
                <div className="off-canvas-sidebar-wrapper">
                    <div className="off-canvas-header">
                        <a className="close-offcanvas" href="#">
                            <span className="fa fa-times" />
                        </a>
                    </div>
                    <div className="off-canvas-content">
                        <aside className="canvas-widget">
                            <div className="logo-sitebar text-center">
                                <img src="assets/img/logos/logo.png" alt="logo" />
                            </div>
                        </aside>
                        <aside className="canvas-widget">
                            <ul className="menu">
                                <li className="menu-item menu-item-has-children">
                                    <a href="#">About</a>
                                </li>
                                <li className="menu-item">
                                    <a href="#">Properties List</a>
                                </li>
                                <li className="menu-item">
                                    <a href="#">Property Detail</a>
                                </li>
                                <li className="menu-item">
                                    <a href="#">Blog</a>
                                </li>
                                <li className="menu-item">
                                    <a href="#">About US</a>
                                </li>
                                <li className="menu-item">
                                    <a href="#">Contact US</a>
                                </li>
                            </ul>
                        </aside>
                        <aside className="canvas-widget">
                            <ul className="social-icons">
                                <li>
                                    <a href="#">
                                        <i className="fa fa-facebook" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fa fa-twitter" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fa fa-google-plus" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fa fa-linkedin" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fa fa-vk" />
                                    </a>
                                </li>
                            </ul>
                        </aside>
                    </div>
                </div>
            </div>


        </>
    );
}

export default LocationResidentielle;

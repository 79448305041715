import React from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import Banner2 from "../../../components/Banner2";
import "./investissement.css";

function InvestissementLocatif() {
  return (
    <>

      <Header title={"Investissement Locatif"} />
      <Banner2 title={"Investissement Locatif"} />





      <div className="about-us content-area-7 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="about-info">
                <h1 style={{ fontWeight: "bold", textTransform: "uppercase", textAlign: "center" }}>Investissement Locatif Clé en Main </h1>
                <h3> Simplifiez-vous la Vie !</h3>
                <div className="agent-experience">

                  <p> Vous souhaitez investir dans l'immobilier locatif sans y consacrer des heures et en évitant les erreurs ? Vous êtes au bon endroit ! Notre équipe d'experts se charge de votre investissement locatif de A à Z pour vous offrir une tranquillité d'esprit totale.</p>

                </div>

                <div className="" style={{ paddingBottom: 10 }}>
                  <a className="btn btn-launch btn-lg text-white" href="/lancer-projet" target="_blank" rel="noopener">Lancer mon projet</a>
                </div>

                <div>
                  <a className="btn btn-appointment btn-lg text-dark" href="" target="_blank" rel="noopener">Prendre RDV avec un expert</a>

                </div>


              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="about-carousel">
                <div
                  id="carouselExampleIndicators3"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <ol className="carousel-indicators">
                    <li
                      data-target="#carouselExampleIndicators3"
                      data-slide-to={0}
                      className="active"
                    />
                    <li data-target="#carouselExampleIndicators3" data-slide-to={1} />
                    <li data-target="#carouselExampleIndicators3" data-slide-to={2} />
                  </ol>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        src="https://framerusercontent.com/images/HhryZHFNR98OjdtTGwRlyh64gNQ.png?scale-down-to=512"
                        alt="property"
                        className="img-fluid"
                        style={{
                          height: "400px",
                          width: "100%"
                        }}
                      />
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div >



      <div class="blog-section content-area-2 bg-white design-perso">
        <div class="container">

          <div class="row" style={{ "padding": "40px", "border-radius": "20px", "background": "linear-gradient(31.980251352385963deg, #af9483 0%, #f1e9e3 100%)" }}>




            <div class="col-lg-8 col-md-12">
              <div className="main-title">
                <h1 style={{ "color": "white" }}> Vous voulez investir dans le locatif ?</h1>

              </div>
              <div class="detail clearfix text-center">

                <p style={{ fontSize: "23px", "color": "white" }}>Déterminez votre capacité d’emprunt en moins de 5 minutes !</p>

                <div className="" style={{ paddingBottom: 10, marginTop: "90px" }}>
                  <a className="btn btn-appointment btn-lg text-dark" href="#" target="_blank" rel="noopener" style={{ background: "white", border: "none" }}>Calculer ma capacité d'emprunt</a>

                </div>

              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="about-carousel">
                <div
                  id="carouselExampleIndicators3"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <ol className="carousel-indicators">
                    <li
                      data-target="#carouselExampleIndicators3"
                      data-slide-to={0}
                      className="active"
                    />
                    <li data-target="#carouselExampleIndicators3" data-slide-to={1} />
                    <li data-target="#carouselExampleIndicators3" data-slide-to={2} />
                  </ol>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        src="assets/img/investissement/budget.png"
                        alt="property"
                        className="img-fluid"
                        style={{
                          height: "400px",
                          width: "100%"
                        }}
                      />
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="about-us content-area-7 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h1 style={{ fontWeight: "bold", textTransform: "uppercase", textAlign: "center" }}>Devenir bailleur n’a jamais été aussi simple </h1>
            </div>

            <div className="col-lg-4 ">
              <div class="framer-container">
                <a class="framer-link" href="./investir-avec-beanstock#1-definition" tabindex="0">
                  <div class="framer-highlighter"></div>
                  <div class="framer-tab-number">1.</div>
                  <div class="framer-tab-text">Définition du projet</div>
                </a>
                <a class="framer-link" href="./investir-avec-beanstock#2-offre" tabindex="0">
                  <div class="framer-highlighter"></div>
                  <div class="framer-tab-number">2.</div>
                  <div class="framer-tab-text">Offre d’achat</div>
                </a>
                <a class="framer-link" href="./investir-avec-beanstock#3-validation" tabindex="0">
                  <div class="framer-highlighter"></div>
                  <div class="framer-tab-number">3.</div>
                  <div class="framer-tab-text">Validation du projet</div>
                </a>
                <a class="framer-link" href="./investir-avec-beanstock#4-promesse" tabindex="0">
                  <div class="framer-highlighter"></div>
                  <div class="framer-tab-number">4.</div>
                  <div class="framer-tab-text">Promesse de vente</div>
                </a>
                <a class="framer-link" href="./investir-avec-beanstock#5-financement" tabindex="0">
                  <div class="framer-highlighter"></div>
                  <div class="framer-tab-number">5.</div>
                  <div class="framer-tab-text">Financement</div>
                </a>
                <a class="framer-link" href="./investir-avec-beanstock#6-acte-authentique" tabindex="0">
                  <div class="framer-highlighter"></div>
                  <div class="framer-tab-number">6.</div>
                  <div class="framer-tab-text">Acte authentique</div>
                </a>
                <a class="framer-link" href="./investir-avec-beanstock#7-travaux" tabindex="0">
                  <div class="framer-highlighter"></div>
                  <div class="framer-tab-number">7.</div>
                  <div class="framer-tab-text">Travaux</div>
                </a>
              </div>
            </div>
            <div className="col-lg-8 ">
              <div class="container container-block9" id="3-validation" name="Item">

                <div class="content">
                  <div class="section-title">DEFINITION DU PROJET</div>
                  <div class="section-subtitle"><b>Élaborez votre stratégie d’investissement</b></div>
                  <div class="section-text">
                    Nos experts vous accompagnent pour estimer votre capacité de financement et déterminer vos critères de recherche. Vous pourrez alors valider votre profil investisseur !

                  </div>
                  <div class="framer-z7s7yn-container">
                    <a class="framer-OSIS9 framer-AvIf3 framer-3hhoA framer-4qrmo framer-v-4qrmo framer-1xt67tc" href="BK Arch’Immomortgage-simulation?_gl=1*1umbgkx*_gcl_aw*R0NMLjE3MjA0ODA2MzkuQ2owS0NRandzdVN6QmhDTEFSSXNBSWNkTG00OEhQN0xqdDRHRHVROTkxZ096MC03c1FwQWJhWjROeG1RSEdXY2NQTUo1aGFEbFNGOENrQWFBbkJhRUFMd193Y0I.*_gcl_au*MTc2MjMwOTAxLjE3MjA0ODA2OTQ." target="_blank" rel="noopener" tabindex="0" data-framer-name="Left icon">
                      <div class="framer-1pbyrgj d-flex" data-framer-name="State=Default, Style=Left icon">
                        <div class="framer-1m77n19-container">
                          <div style={{ "width": '20px', 'height': '20px', "margin-top": "5px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="100%" height="100%" fill="none">
                              <path d="M10.625 12.5L13.125 10M13.125 10L10.625 7.5M13.125 10L6.875 10M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#af9483" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                          </div>
                        </div>
                        <div class="framer-1dt9u4m" data-framer-component-type="RichTextContainer">
                          <p class="framer-text framer-styles-preset-mgjswx design-bl-perso ml-2" data-styles-preset="FHXWUWZk1" style={{ "text-decoration": "underline" }}>Calculer ma capacité d’emprunt</p>
                        </div>
                      </div>
                    </a>
                  </div>

                </div>
                <div class="visual">

                  <img src="https://framerusercontent.com/images/kVh1sJQiQdWkvnvysB9BOfgpnKY.png?scale-down-to=512" alt="Label_6" style={{ "margin-top": "20px;" }} />
                </div>
              </div>

              <div class="container container-block9" id="3-validation" name="Item">

                <div class="content">
                  <div class="section-title">OFFRE D’ACHAT</div>
                  <div class="section-subtitle"><b>Trouvez le projet locatif idéal pour vous</b></div>
                  <div class="section-text">
                    Votre conseiller en immobilier locatif vous guidera dans la sélection et la négociation d’un projet qui répond à vos critères parmi notre gamme de projets d’investissement !

                  </div>
                  <div class="framer-z7s7yn-container">
                    <a class="framer-OSIS9 framer-AvIf3 framer-3hhoA framer-4qrmo framer-v-4qrmo framer-1xt67tc" href="#" target="_blank" rel="noopener" tabindex="0" data-framer-name="Left icon">
                      <div class="framer-1pbyrgj d-flex" data-framer-name="State=Default, Style=Left icon">
                        <div class="framer-1m77n19-container">
                          <div style={{ "width": '20px', 'height': '20px', "margin-top": "5px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="100%" height="100%" fill="none">
                              <path d="M10.625 12.5L13.125 10M13.125 10L10.625 7.5M13.125 10L6.875 10M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#af9483" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                          </div>
                        </div>
                        <div class="framer-1dt9u4m" data-framer-component-type="RichTextContainer">
                          <p class="framer-text framer-styles-preset-mgjswx design-bl-perso ml-2" data-styles-preset="FHXWUWZk1" style={{ "text-decoration": "underline" }}>Tous nos projets</p>
                        </div>
                      </div>
                    </a>
                  </div>

                </div>
                <div class="visual">

                  <img src="assets/img/investissement/offre.jpeg" alt="Label_6" style={{ "margin-top": "20px;" }} />
                </div>
              </div>


              <div class="container container-block9" id="3-validation" name="Item">

                <div class="content">
                  <div class="section-title">VALIDATION DU PROJET</div>
                  <div class="section-subtitle"><b>Vérifiez tous les éléments de votre projet locatif</b></div>
                  <div class="section-text">
                    Afin de vous éviter les mauvaises surprises, nos experts épluchent PV d’AG et diagnostics, et vous transmettent leurs analyses détaillées ainsi que votre devis travaux.
                    <br />
                    Vous pourrez également visiter le bien et valider votre financement pour avancer sereinement sur votre projet !
                  </div>
                  <div class="text-box">Au bout de la période de vérification, des frais de service de 500 € vous seront facturés pour confirmer votre intérêt pour le projet.</div>
                </div>
                <div class="visual">

                  <img src="https://framerusercontent.com/images/Gcoq7dMjnkz6Wga66iWThrVFk.png" alt="Label_6" style={{ "margin-top": "20px;" }} />
                </div>
              </div>

              <div class="container container-block9" id="3-validation" name="Item">

                <div class="content">
                  <div class="section-title">PROMESSE DE VENTE</div>
                  <div class="section-subtitle"><b>Votre transaction immobilière gérée par des professionnels</b></div>
                  <div class="section-text">
                    Nos notaires partenaires préparent le dossier de vente et valident la promesse, vous permettant ainsi de signer ce premier acte notarié en toute sérénité. Votre chasseur immobilier vous accompagne tout au long du processus et assure la liaison avec les notaires.

                  </div>
                </div>
                <div class="visual">

                  <img src="assets/img/investissement/notaire.jpeg" alt="Label_6" style={{ "margin-top": "20px;" }} />
                </div>
              </div>

              <div class="container container-block9" id="3-validation" name="Item">

                <div class="content">
                  <div class="section-title">FINANCEMENT</div>
                  <div class="section-subtitle"><b>Assurez la sécurisation de votre financement</b></div>
                  <div class="section-text">
                    Nos experts en crédit immobilier vous accompagnent à chaque étape, de la constitution de votre dossier à l’obtention de votre crédit, afin de choisir la meilleure offre bancaire.

                  </div>
                </div>
                <div class="visual">

                  <img src="assets/img/investissement/financement.jpeg" alt="Label_6" style={{ "margin-top": "20px;" }} />
                </div>
              </div>


              <div class="container container-block9" id="3-validation" name="Item">

                <div class="content">
                  <div class="section-title">Acte Authentique</div>
                  <div class="section-subtitle"><b>Félicitations, vous êtes désormais propriétaire !</b></div>
                  <div class="section-text">
                    Après la signature de votre offre de crédit, votre notaire finalise la vente. Une fois l’acte authentique signé, vous devenez officiellement propriétaire de votre bien !

                  </div>
                  <div class="text-box">Ce n'est qu'à ce moment là que vous payez les frais de transaction de notre agence (prix du bien et montant des travaux).</div>
                </div>
                <div class="visual">

                  <img src="assets/img/investissement/acte.jpeg" alt="Label_6" style={{ "margin-top": "20px;" }} />
                </div>
              </div>

              <div class="container container-block9" id="3-validation" name="Item">

                <div class="content">
                  <div class="section-title">Travaux</div>
                  <div class="section-subtitle"><b>Gestion de vos travaux de A à Z par nos architectes</b></div>
                  <div class="section-text">
                    BK Arch’Immo , notre filiale spécialisée dans les travaux, prend en charge la rénovation de votre bien. De la conception architecturale à l'ameublement en passant par le suivi du chantier, nos architectes s'occupent de tout. Pour vous garantir une tranquillité d'esprit totale,  BK Arch’Immo assure le respect du budget, des délais de chantier et la mise aux normes de votre propriété !

                  </div>
                  <div class="">
                    <p className="icon-blk"> <i className="fa fa-wrench pr-2 text-white" />Budget travaux garanti</p>
                    <p className="icon-blk"> <i className="fa fa-hourglass-end pr-2 text-white" />Délais de chantier garanti</p>
                    <p className="icon-blk"> <i className="fa fa-home pr-2 text-white" />Remise aux normes garantie</p>
                  </div>
                  <div class="framer-z7s7yn-container">
                    <a class="framer-OSIS9 framer-AvIf3 framer-3hhoA framer-4qrmo framer-v-4qrmo framer-1xt67tc" href="#" target="_blank" rel="noopener" tabindex="0" data-framer-name="Left icon">
                      <div class="framer-1pbyrgj d-flex" data-framer-name="State=Default, Style=Left icon">
                        <div class="framer-1m77n19-container">
                          <div style={{ "width": '20px', 'height': '20px', "margin-top": "5px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="100%" height="100%" fill="none">
                              <path d="M10.625 12.5L13.125 10M13.125 10L10.625 7.5M13.125 10L6.875 10M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#af9483" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                          </div>
                        </div>
                        <div class="framer-1dt9u4m" data-framer-component-type="RichTextContainer">
                          <p class="framer-text framer-styles-preset-mgjswx design-bl-perso ml-2" data-styles-preset="FHXWUWZk1" style={{ "text-decoration": "underline" }}>Nos réalisations</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="visual">

                  <img src="https://framerusercontent.com/images/pFf2FDACRY56BAmbVMtVoDWN278.png?scale-down-to=512" alt="Label_6" style={{ "margin-top": "20px;" }} />
                </div>
              </div>


              <div class="container container-block9" id="3-validation" name="Item">

                <div class="content">
                  <div class="section-title">GESTION LOCATIVE</div>
                  <div class="section-subtitle"><b>Maximisez vos revenus locatifs</b></div>
                  <div class="section-text">
                    Pour percevoir au plus vite vos premiers revenus locatifs, BK Arch’Immo trouve votre premier locataire et s’occupe de la gestion locative.

                  </div>
                  {/* <div class="">
                    <p className="icon-blk"> <i className="fa fa-clock-o pr-2" />Bien loué sous 45 jours</p>
                    <p className="icon-blk"> <i className="fa fa-eur pr-2" />3 mois de loyers garantis</p>
                  </div> */}
                  <div class="framer-z7s7yn-container">
                    <a class="framer-OSIS9 framer-AvIf3 framer-3hhoA framer-4qrmo framer-v-4qrmo framer-1xt67tc" href="#" target="_blank" rel="noopener" tabindex="0" data-framer-name="Left icon">
                      <div class="framer-1pbyrgj d-flex" data-framer-name="State=Default, Style=Left icon">
                        <div class="framer-1m77n19-container">
                          <div style={{ "width": '20px', 'height': '20px', "margin-top": "5px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="100%" height="100%" fill="none">
                              <path d="M10.625 12.5L13.125 10M13.125 10L10.625 7.5M13.125 10L6.875 10M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#af9483" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                          </div>
                        </div>
                        <div class="framer-1dt9u4m" data-framer-component-type="RichTextContainer">
                          <p class="framer-text framer-styles-preset-mgjswx design-bl-perso ml-2" data-styles-preset="FHXWUWZk1" style={{ "text-decoration": "underline" }}>Notre fondatrice en parle</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="visual">

                  <img src="assets/img/investissement/gestion.jpeg" alt="Label_6" style={{ "margin-top": "20px;" }} />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>







      {/* Our newslatters 2 start */}
      <div className="our-newslatters-2">
        <div className="container">
          <div className="row inner">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="intro-text">
                <h3>S'abonner à la Newsletter</h3>
                <p>
                  Inscrivez-vous pour recevoir des informations et des mises à jour. Ne vous inquiétez pas, nous n'enverrons pas de spam !
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="Subscribe-box">
                <form className="form-inline" action="#" method="GET">
                  <input
                    type="text"
                    className="form-control mb-sm-0"
                    id="inlineFormInputName4"
                    placeholder="Email "
                  />
                  <button type="submit" className="btn">
                    Envoyer
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Our newslatters 2 end */}
      {/* Footer start */}
      <Footer />
      {/* Footer end */}
      {/* Full Page Search */}
      <div id="full-page-search">
        <button type="button" className="close">
          ×
        </button>
        <form action="#" className="search">
          <input type="search" defaultValue="" placeholder="type keyword(s) here" />
          <button type="button" className="btn btn-sm btn-color">
            Search
          </button>
        </form>
      </div>
      {/* Property Video Modal */}
      <div
        className="modal property-modal fade"
        id="propertyModal"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6 modal-left">
                  <div className="modal-left-content">
                    <div
                      id="modalCarousel"
                      className="carousel slide"
                      data-ride="carousel"
                    >
                      <div className="carousel-inner" role="listbox">
                        <div className="carousel-item active">
                          <iframe
                            className="modalIframe"
                            src="https://www.youtube.com/embed/5e0LxrLSzok"
                            allowFullScreen=""
                          />
                        </div>
                        <div className="carousel-item">
                          <img src="assets/img/test-alt.jpg" alt="Test ALT" />
                        </div>
                        <div className="carousel-item">
                          <img src="assets/img/test-alt-2.jpg" alt="Test ALT" />
                        </div>
                      </div>
                      <div className="btn-modal">
                        <a
                          className="control control-prev"
                          href="#modalCarousel"
                          role="button"
                          data-slide="prev"
                        >
                          <i className="fa fa-angle-left" />
                        </a>
                        <a
                          className="control control-next"
                          href="#modalCarousel"
                          role="button"
                          data-slide="next"
                        >
                          <i className="fa fa-angle-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 modal-right">
                  <div className="modal-right-content bg-white">
                    <h1>
                      <a href="#">Find Your Properties</a>
                    </h1>
                    <section>
                      <h3>Features</h3>
                      <ul className="bullets">
                        <li>
                          <i className="fa fa-arrow-right" /> Double Bed
                        </li>
                        <li>
                          <i className="flaticon-swimmer" /> Swimming Pool
                        </li>
                        <li>
                          <i className="flaticon-bath" /> 2 Bathroom
                        </li>
                        <li>
                          <i className="flaticon-car-repair" /> Garage
                        </li>
                        <li>
                          <i className="flaticon-parking" /> Parking
                        </li>
                        <li>
                          <i className="flaticon-theatre-masks" /> About Theater
                        </li>
                        <li>
                          <i className="flaticon-old-typical-phone" /> Telephone
                        </li>
                        <li>
                          <i className="flaticon-green-park-city-space" /> Private
                          space
                        </li>
                      </ul>
                    </section>
                    <section>
                      <h3>Overview</h3>
                      <dl>
                        <dt>Area</dt>
                        <dd>2500 Sq Ft</dd>
                        <dt>Condition</dt>
                        <dd>New</dd>
                        <dt>Year</dt>
                        <dd>2020</dd>
                        <dt>Price</dt>
                        <dd>
                          <strong>$178,000</strong>
                        </dd>
                      </dl>
                    </section>
                    <a
                      href="#"
                      className="btn btn-show btn-theme"
                    >
                      Show Detail
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Off-canvas sidebar */}
      <div className="off-canvas-sidebar">
        <div className="off-canvas-sidebar-wrapper">
          <div className="off-canvas-header">
            <a className="close-offcanvas" href="#">
              <span className="fa fa-times" />
            </a>
          </div>
          <div className="off-canvas-content">
            <aside className="canvas-widget">
              <div className="logo-sitebar text-center">
                <img src="assets/img/logos/logo.png" alt="logo" />
              </div>
            </aside>
            <aside className="canvas-widget">
              <ul className="menu">
                <li className="menu-item menu-item-has-children">
                  <a href="#">About</a>
                </li>
                <li className="menu-item">
                  <a href="#">Properties List</a>
                </li>
                <li className="menu-item">
                  <a href="#">Property Detail</a>
                </li>
                <li className="menu-item">
                  <a href="#">Blog</a>
                </li>
                <li className="menu-item">
                  <a href="#">About US</a>
                </li>
                <li className="menu-item">
                  <a href="#">Contact US</a>
                </li>
              </ul>
            </aside>
            <aside className="canvas-widget">
              <ul className="social-icons">
                <li>
                  <a href="#">
                    <i className="fa fa-facebook" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-google-plus" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-linkedin" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-vk" />
                  </a>
                </li>
              </ul>
            </aside>
          </div>
        </div>
      </div>


    </>
  );
}

export default InvestissementLocatif;
